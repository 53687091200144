<template>
  <v-dialog
    v-model="dialogServicoPrestado"
    @click:outside="$emit('update:dialogServicoPrestado', false)"
    @keydown.esc="$emit('update:dialogServicoPrestado', false)"
    width="500"
  >
    <v-card flat :loading="loading">
      <v-card-title
        class="d-flex align-center subtitle-1 tocs_gray_1 px-4 py-3"
      >
        {{
          edit
            ? $tc("global.editar") + " " + $tc("global.servicoPrestado")
            : $tc("global.add") + " " + $tc("global.servicoPrestado")
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogServicoPrestado', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row class="">
            <!-- Nome -->
            <v-col cols="12" md="12">
              <v-text-field
                v-model="descricao"
                :label="$tc('global.descricao')"
                outlined
                dense
                :rules="formRules"
                required
                hide-details
              ></v-text-field> </v-col
          ></v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-3">
        <v-spacer></v-spacer>
        <v-btn
          color="button_2"
          class="white--text"
          depressed
          small
          :disabled="!validForm"
          :loading="loading"
          @click="edit ? updateServicoPrestado() : createServicoPrestado()"
        >
          {{ edit ? $tc("global.salvar") : $tc("global.criar") }}
          <v-icon right>{{ edit ? "mdi-plus" : "mdi-check" }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { inputRequired } from "@/helpers/utils.js";
import {
  getServicoPrestado,
  putServicoPrestado,
  postServicoPrestado,
} from "@/api/servicosPrestados/servicosPrestados.js";
export default {
  name: "DialogServicoPrestado",

  components: {},

  props: {
    dialogServicoPrestado: {
      type: Boolean,
      require: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    servico_id: {
      type: [Number, String],
      required: false,
    },
  },

  data() {
    return {
      loading: false,
      validForm: true,
      descricao: "",
      formRules: [inputRequired],
    };
  },

  computed: {},

  watch: {},

  methods: {
    updateServicoPrestado() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        putServicoPrestado(this.servico_id, {
          descricao: this.descricao,
        })
          .then(() => {
            this.$emit("update:dialogServicoPrestado", false);
            this.$emit("fetch-servicos-prestados");
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },

    createServicoPrestado() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        postServicoPrestado({
          descricao: this.descricao,
        })
          .then((res) => {
            this.$emit("update:dialogServicoPrestado", false);
            this.$emit("fetch-servicos-prestados", res.data.result);
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },

    getServicoPrestado() {
      this.loading = true;
      return getServicoPrestado(this.servico_id)
        .then((res) => {
          this.descricao = res.descricao;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    if (this.edit) {
      await this.getServicoPrestado();
    }
  },
};
</script>
